@import "../base/color";
@import "../base/typo";
@import "../base/utils";

.stack-box-wrapper {
  background-color: $ss-gray-10;
  color: $ss-gray-100;
}

.required-stack-box {
  margin-top: 40px;
  padding: 24px;
  background-color: $ss-gray-10;
  color: $ss-gray-100;
  border-radius: 12px;

  .stack-box {
    + .stack-box {
      margin-top: 32px;
    }
  }

  .stack-box__title {
    @include subtitle-text-1;
    color: $ss-gray-100;
    margin-bottom: 24px;
  }
}

.stack-container {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  gap: 8px 16px;
  align-items: flex-start;

  &.column {
    flex-direction: column;
  }
  &.flex-wrap {
    flex-wrap: wrap;
    overflow-x: hidden;
  }
  &.flex-hidden {
    overflow-x: hidden;
  }
}

.stack-card {
  background: $ss-white;
  border: 1px solid $ss-gray-20;
  border-radius: 4px;
  flex: 0 0 auto;
  cursor: default;

  .stack-card__column {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    background: $ss-white;

    .skill {
      @include body-text-3;
      color: $ss-gray-100;
      text-align: center;
    }

    .year {
      @include body-text-3;
      color: $ss-gray-70;
    }
  }

  .stack-card__row {
    display: flex;
    align-items: center;
    gap: 6px;
    background: $ss-white;

    .skill {
      @include caption-text-1;
      color: $ss-gray-100;
    }
  }
}

.simple-stack-card {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 4px;
  padding: 8px 12px 8px 8px;
  background: $ss-white;
  border: 1px solid $ss-gray-30;
  border-radius: 4px;
  cursor: default;

  .simple-stack-card__name {
    @include body-text-3;
    color: $ss-gray-100;
  }
}

.simple-career-stack-card {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 6px;
  padding: 6px 12px;
  background: $ss-gray-10;
  border-radius: 6px;

  .simple-career-stack-card__name {
    @include body-text-3;
    color: $ss-gray-100;
  }
}

.stack-bundle-wrapper {
  padding: 16px 10px;
  display: flex;
  gap: 8px;
  align-items: stretch;
  border-radius: 8px;

  .stack-bundle-box {
    display: flex;
    flex-wrap: wrap;
    gap: 10px 16px;

    span {
      @include caption-text-1;
      color: $ss-gray-90;
    }

    .stack-bundle-box__item {
      display: flex;
      align-items: center;
      gap: 6px;
    }

    &.extra-margin {
      margin-right: 8px;
      margin-left: 8px;
    }
  }

  .next-page {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}


@media (max-width: 1079px) {
  .required-stack-box {
    padding: 20px;

    .stack-box {
      + .stack-box {
        margin-top: 24px;
      }
    }
  }
}
