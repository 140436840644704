@import "color";
@import "typo";
@import "utils";

.tabs {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  overflow-x: auto;
  -ms-overflow-style: none;
  gap: 16px;

  &::-webkit-scrollbar {
    display: none;
  }

  .tab {
    @include body-text-4;
    color: $ss-gray-90;
    padding: 8px 0;
    margin: 0 12px;
    cursor: pointer;
    user-select: none;

    &.active {
      padding-bottom: 7px;
      color: $ss-blue-50;
      border-bottom: 1px solid $ss-blue-50;

    }
  }
}

.apply-tabs {
  width: 100%;
  display: flex;
  border-bottom: 1px solid $ss-blue-50;

  &.faint {
    .apply-tab {
      color: $ss-gray-60 !important;
    }
    .apply-tab-active {
      color: $ss-blue-50 !important;
    }
  }

  .apply-tab {
    @include body-text-3;
    color: $ss-gray-90;
    padding: 12px 20px;
    white-space: nowrap;
    cursor: pointer;
    user-select: none;

    border: 1px solid $ss-gray-50;
    border-bottom: none;
    border-right: none;

    &:last-of-type {
      border-right: 1px solid $ss-gray-50;
    }
    &.apply-tab-active {
      position: relative;
      margin-right: -1px;
      margin-bottom: -1px;
      color: $ss-blue-50;
      border-color: $ss-blue-50;
      border-bottom: 1px solid $ss-white;
      border-right: 1px solid $ss-blue-50;
    }
  }
}

.separator {
  width: 1px;
  height: 14px;
  border-right: 1px solid $ss-gray-40;
  margin: 0 -8px;
  position: relative;
  bottom: 2px;
}

.scrollable-tabs {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  overflow-x: auto;
  border-bottom: 1px solid $ss-gray-20;

  .tab {
    flex-shrink: 0;
    padding-bottom: 20px;
    cursor: pointer;
    user-select: none;

    display: flex;
    flex-direction:column;
    align-items: center;

    &.active {
      padding-bottom: 19px;
      color: $ss-gray-100 !important;
      border-bottom: 1px solid $ss-blue-50;
    }
  }

  .company-home-tab {
    @extend .tab;
    @include body-text-3;
    color: $ss-gray-100;
    padding-top: 10px;
    padding-bottom: 14px;

    &.active {
      padding-bottom: 13px;
      color: $ss-gray-100 !important;
      border-bottom: 1px solid $ss-gray-100;
    }
  }

  .stack-tab {
    @extend .tab;
  }
  .service-tab {
    @extend .tab;
    @include subtitle-text-2;
    color: $ss-gray-60;
    padding-top: 10px;
    padding-bottom: 16px;

    &.active {
      padding-bottom: 15px;
      color: $ss-gray-100 !important;
      border-bottom: 1px solid $ss-gray-100;
    }
  }

  .my-service-tab {
    @extend .service-tab;
    flex-direction: row;
    gap: 9px;
  }
}

.tab-dropdown {
  position: absolute;
  width: 112px;

  background-color: $ss-white;
  border-radius: 8px;
  padding: 4px 0;
  box-shadow: 0 10px 37px rgba(0, 0, 0, 0.15);

  .tab-dropdown-item{
    @include body-text-2;
    border-radius: 8px;
    padding: 8px;
    cursor: pointer;

    &:hover{
      background-color: $ss-gray-10;
    }
  }
}

@media (min-width: 1080px) {
  .scrollable-tabs {
    gap: 41px;
    overflow-x: auto;

    &::-webkit-scrollbar {
      height: 0;
    }

    &:hover {
      &::-webkit-scrollbar {
        height: 6px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $ss-gray-40;
        border-radius: 32px;
      }
      &::-webkit-scrollbar-track {
        background-color: transparent;
      }
    }

    .stack-tab {
      @include body-text-1;
      color: $ss-gray-60;
    }
  }
}

@media (max-width: 1079px) {
  .scrollable-tabs {
    gap: 20px;

    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .stack-tab {
      @include body-text-3;
      color: $ss-gray-60;
    }
  }
}