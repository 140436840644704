@import "../base/typo";
@import "../base/color";
@import "../base/utils";

.content-box {
  @include body-text-3;
  color: $ss-gray-100;
  @extend .flex-box;

  .img-thumbnail {
    border: 1px solid $ss-gray-30;
    border-radius: 50%;
  }

  .content-with-img {
    margin-left: 8px;
  }
}

.company-logo-index-wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
}


.company-logo-index {
  display: flex;
  align-items: center;
  gap: 20px;

  img {
    border-radius: 8px;
  }

  .name {
    @include h7-m;
    color: $ss-gray-100;
  }

  .name-wrapper {
    display: inline-flex;
    flex-direction: column;
    gap: 8px;
    width: calc(100% - 72px);

    .name {
      white-space: pre-wrap;
    }

    button {
      max-width: 120px;
    }
  }

  &.name-with-score {
    align-items: flex-start;
  }

  &.is-summary {
    .name-wrapper {
      .name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

@media (min-width: 1080px) {
  .mobile {
    display: none !important;
  }
}

@media (max-width: 1079px) {
  .pc {
    display: none !important;
  }
}