@import "color";
@import "typo";
@import "utils";

.chip--filled {
  @extend .chip;
  background-color: $ss-blue-10;
  color: $ss-gray-100;
}
.chip--text {
  @extend .chip;
  background-color: $ss-gray-20;
  color: $ss-gray-100;
}
.chip--outline {
  @extend .chip;
  outline: 1px solid $ss-gray-40;
  background-color: $ss-white;
}

.chip--sm {
  padding: 4px 6px;
  @include caption-text-2;
}

.chip--md {
  padding: 4px 8px;
  @include caption-text-1;
}

.chip--lg {
  padding: 3px 8px 5px;
  @include body-text-3;
}
.chip--xl {
  padding: 5px 12px 7px;
  @include body-text-3;
}


.chip {
  border: none;
  border-radius: 6px;
  -webkit-appearance: none;
  -webkit-border-radius: 6px;
  cursor: pointer;

  &.unclickable {
    cursor: default;
  }
}

.chip-close {
  @extend .chip;
}

.content-box {
  @extend .flex-box;

  .content-with-icon {
    margin-right: 6px;
  }
}