@import "../base/typo";
@import "../base/color";
@import "../base/utils";

.box-culture-review-result {
  .title-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      @include subtitle-text-1;
      color: $ss-gray-100;
    }
  }
}


.result-item-wrapper {
  border-bottom: 1px dashed $ss-gray-40;
  &:first-of-type {
    padding-top: 0;
  }
  &:last-of-type {
    border-bottom: none;
  }
}

.result-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  padding: 32px 0;

  .q-wrapper {
    @include body-text-2;
    color: $ss-black;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    margin-bottom: 8px;
  }

  .culture-result-bar-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .bar-wrapper {
      display: flex;

      .bar-chart {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 8px;

        .bar {
          @include body-text-3;
          color: $ss-green-50;
          background: $ss-green-5;
          border-radius: 2px;
          padding: 8px 12px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          &.majority {
            @include btn-text-3;
            background-color: $ss-green-50;
            color: $ss-white;
            border: 2px solid $ss-green-50;
          }

          &.equal {
            @include btn-text-3;
            background-color: $ss-sky-50;
            color: $ss-white;
            border: 2px solid $ss-sky-50;
          }
        }

        &.empty {
          .bar {
            background: $ss-gray-30;
            color: $ss-gray-50;
          }
        }
      }

    }

    .answer-wrapper {
      display: flex;
      justify-content: space-between;
      @include body-text-3;
      color: $ss-gray-70;

      p.majority {
        @include btn-text-3;
        color: $ss-gray-90;
      }
    }
  }

  .multiple-answers {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 8px;
    justify-content: center;

    button {
      flex-shrink: 0;
    }
  }

  .comment-wrapper {
    width: 100%;
    padding: 16px;
    background-color: $ss-gray-10;
    border-radius: 4px;

    summary {
      @include caption-text-1;
      color: $ss-gray-60;
      user-select: none;
      cursor: pointer;
      list-style: none;
      user-focus: none;

      display: flex;
      justify-content: space-between;
      align-items: center;

      svg {
        path {
          color: $ss-gray-60;
        }
      }
    }

    .comment {
      @include body-text-5;
      color: $ss-gray-90;
      white-space: pre-wrap;
      margin-top: 10px;
    }
  }

  &.is-summary {
    gap: 8px;
    padding: 0;
    border-bottom: none;

    &:first-of-type {
      margin-top: 0;
    }

    .q-wrapper {
      @include caption-text-3;
      color: $ss-gray-70;
      margin-bottom: 0;
    }
  }
}

.culture-result-bar-wrapper-simple {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .bar-wrapper {
    display: flex;
    @include body-text-3;
    color: $ss-gray-70;

    .bar-chart {
      width: 100%;
      height: 8px;
      display: flex;

      .bar {
        background: $ss-green-20;

        &.majority {
          background-color: $ss-green-50;
          color: $ss-white;
          border-radius: 4px 0 0 4px;
        }

        &.left {
          text-align: left;
        }

        &.right {
          text-align: right;
        }
      }

      &.empty {
        .bar {
          background: $ss-gray-30;
        }
      }
    }
  }

  .answer-wrapper {
    @include caption-text-3;
    color: $ss-gray-50;
    display: flex;
    justify-content: space-between;

    p.majority {
      @include caption-text-3;
      color: $ss-green-50;
    }
  }
}

.empty-result-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  padding: 40px 0;

  p {
    @include body-text-2;
    color: $ss-black;
  }
}

.denied-box {
  position: relative;

  &.is-summary > span {
    height: 62px !important;
  }
}

@media (min-width: 1080px) {
  .result-item {
    max-width: 400px;
    margin: 0 auto;
  }
}