@import "../base/typo";
@import "../base/color";
@import "../base/utils";

.matching-score-layer {
  display: flex;
  align-items: center;
  gap: 6px;

  .score-wrapper {
    @include caption-text-3;
    display: flex;
    align-items: center;
    gap: 4px;

    svg {
      flex-shrink: 0;
    }

    &.culture {
      color: $ss-green-50;
    }

    &.stacks {
      color: $ss-blue-50;
    }

    &.empty {
      color: $ss-gray-50;
    }
  }

  .sep {
    width: 1px;
    height: 12px;
    background: $ss-gray-40;
  }
}

@media (min-width: 1080px) {
  .mobile {
    display: none !important;
  }
}

@media (max-width: 1079px) {
  .pc {
    display: none !important;
  }
}

@media (max-width: 375px) {
  .matching-score-layer {
    .score-wrapper {
      gap: 0;
    }
  }
}