@import "./base/typo";
@import "./base/color";
@import "./base/utils";

@media (min-width: 1080px) {
  .container {
    margin: 0 auto;

    .jobs {
      .title {
        @include h5;
        color:  $ss-gray-100;
        text-align: left;
        margin-bottom: 40px;
      }

      .stack-job-container {
        .job-container {
          margin-top: 40px;
          display: flex;
          flex-wrap: wrap;
          gap: 20px;

          article {
            width: 255px;
          }
        }
        .position-link {
          margin-top: 40px;
        }
      }
    }
    .zigzag {
      display: flex;
      flex-direction: column;
      gap: 70px;

      .element {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row-reverse;

        &:nth-child(even) {
          flex-direction: row;
        }
        gap: 40px;
        .img-png{
          width: 519px;
          height: 376px;
        }
        .content {
          .title {
            @include h4-landing;
          }
          .subtitle {
            @include subtitle-text-2-landing;
            color: $ss-gray-80;
          }
        }
        .subtitle.mobile {
          display: none;
        }
      }
    }
  }
  .culture {
    padding: 80px 0 100px;
    .content-wrapper {
      max-width: 1080px;
      margin: 0 auto;

      .title {
        @include h4;
      }
      .culture-card-wrapper {
        margin-top: 40px;

        .culture-card {
          padding: 40px 0 48px;

          .title {
            @include h6;
            margin-top: 12px;
          }
          .text {
            @include subtitle-text-2-landing;
            margin-top: 12px;
            color: $ss-gray-80;
          }
        }
      }
    }
  }
  .cta {
    padding: 120px 0;

    .title {
      @include h4-landing;
      font-weight: 400;
      color: $ss-gray-100;

      &.mobile {
        display: none;
      }
    }

    .subtitle {
      @include h7;
      color: $ss-gray-80;
      margin-bottom: 40px;
    }
  }
  .faqs {
    max-width: 1080px;
    margin: 0 auto;
    padding-top: 120px;

    .title {
      @include h4;
    }
    .faq-container {
      display: flex;
      justify-content: space-between;
      margin-top: 40px;

      .faq-wrapper {
        width: 520px;
        padding: 40px 48px;
        border-radius: 24px;

        .title {
          @include h6;
        }
        .content {
          @include body-text-1;
          margin-bottom: 20px;
        }
      }
    }
    .show-more {
      @include content-text-1;
      display: inline-block;
      padding: 8px;
      float: right;
    }
  }
  .banner-wrapper {
    background-color: $ss-blue-5;
    border-radius: 8px;

    .banner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 32px;

      .content-wrapper {
        display: flex;
        align-items: center;
        gap: 20px;

        .content {
          @include h7;
          color: $ss-gray-100;

          &.mobile {
            display: none;
          }
        }
      }
    }
  }
  .culture-code {
    .content-wrapper {
      .title {
        @include h5;
        color: $ss-gray-100;
      }
      .content {
        margin-top: 60px;

        .codes {
          .code {
            @include h7;
            color: $ss-black;
          }
        }
      }
    }
    .link-borderless {
      justify-content: flex-start;
      margin-top: 28px;
    }
  }
}

@media (max-width: 1079px) {
  .container {
    padding: 20px 24px;
    .jobs {
      .title {
        @include subtitle-text-1;
        color:  $ss-gray-100;
        text-align: center;
        margin-bottom: 28px;
      }
      .stack-job-container {
        .job-container {
          margin-top: 16px;
          display: flex;
          flex-direction: column;
          gap: 16px;
        }
        .position-link {
          margin-top: 16px;
        }
      }
    }
    .zigzag {
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      gap: 20px;

      .element {
        display: block;
        .img-png-box {
          display: flex;
          justify-content: center;
        }
        .content {
          .title {
            @include h6;
          }
          .subtitle {
            @include content-text-2;
          }
        }
        .img.pc {
          display: none;
        }
        .subtitle.pc {
          display: none;
        }
      }
    }
  }
  .culture {
    padding: 40px 24px 60px;
    .title {
      @include h6;
    }
    .culture-card-wrapper {
      flex-direction: column;
      margin-top: 36px;

      .culture-card {
        @include h7;
        padding: 32px 24px;
        .text {
          @include content-text-2;
          margin-top: 12px;
          color: $ss-gray-80;
        }
      }
    }
  }
  .cta {
    padding: 40px 0;

    .title {
      @include h6;
      color: $ss-gray-100;
      &.pc {
        display: none;
      }
    }
    .subtitle {
      @include body-text-2;
      color: $ss-gray-80;
      margin-bottom: 24px;
    }
  }
  .faqs {
    padding: 60px 24px;

    .title {
      @include h6;
    }
    .faq-container {
      display: flex;
      flex-direction: column;
      gap: 24px;
      margin-top: 36px;

      .faq-wrapper {
        padding: 32px 24px;
        border-radius: 16px;

        .title {
          @include h7;
        }
        .content {
          @include content-text-2;
          margin-bottom: 12px;
        }
      }
    }
    .show-more {
      @include content-text-2;
      display: inline-block;
      float: right;
      padding: 12px;
    }
  }
  .banner-wrapper {
    background-color: $ss-blue-5;
    border-radius: 8px;

    .banner {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      padding: 32px 0;

      .content-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        .content {
          @include h7;
          color: $ss-gray-100;
          text-align: center;

          &.pc {
            display: none;
          }
        }
      }
    }
  }
  .culture-code {
    .content-wrapper {
      .title {
        @include subtitle-text-1;
        color: $ss-gray-100;
        text-align: center;
      }
      .content {
        margin-top: 40px;

        .codes {
          .code {
            @include body-text-1;
            color: $ss-black;
          }
        }
      }
    }
    .link-borderless {
      justify-content: center;
      margin-top: 32px;
    }
  }
}

.container {
  max-width: 1080px;
  .zigzag {
    .element {
      .content {
        .title {
          color: $ss-gray-100;
        }
        .subtitle {
          color: $ss-gray-80;
          margin-top: 24px;
        }
        .link {
          @include btn-text-2;
          @extend .flex-box;
          display: inline-block;
          text-decoration: none;
          color: $ss-blue-50;
          margin-top: 24px;

          svg {
            margin-left: 7px;
          }
        }
      }
    }
  }
}
.culture-code {
  background: $ss-gray-10;

  .content-wrapper {
    .content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .codes {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .code {
          @extend .flex-box;
          gap: 12px;
          padding: 24px;
          background-color: $ss-white;
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
          border-radius: 2px 24px 24px 24px;

          svg {
            flex-shrink: 0;
          }
        }
      }
    }
  }

  .link-borderless {
    @include btn-text-2;
    color: $ss-blue-50;
  }
}
.culture {
  background: linear-gradient(101.31deg, $ss-blue-50 0%, $ss-sky-50 100%);
  .content-wrapper {
    display: flex;
    flex-direction: column;

    .title-wrapper {
      text-align: center;
      .title {
        color: $ss-white;
      }
    }
    .culture-card-wrapper {
      display: flex;
      align-items: center;
      gap: 24px;
      color: $ss-gray-100;

      .culture-card {
        width: 100%;
        background: $ss-white;
        box-shadow: 0 10px 37px rgba(0, 0, 0, 0.15);
        border-radius: 24px;
        text-align: center;
      }
    }
  }
}
.cta {
  text-align: center;
  .subtitle {
    margin-top: 12px;
  }
}
.issues {
  background-color: $ss-gray-10;
  text-align: center;
}
.faqs {
  .title {
    text-align: center;
  }
  .faq-container {
    .faq-wrapper {
      background-color: $ss-gray-10;
      .title {
        color: $ss-gray-100;
        margin-bottom: 24px;
      }
      .content {
        a {
          color: $ss-gray-80;
          text-decoration: none;
        }

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
  .show-more {
    cursor: pointer;
    a {
      color: $ss-blue-50;
      text-decoration: none;
    }
  }
}

.landing-banner-wrapper {
  background-color: $ss-white;
  width: 100%;
  border-radius: 8px;

  .carousel-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    text-align: center;
    background-color: $ss-white;
    padding: 0 10px;

    .img-wrapper {
      width: 100%;
      padding: 20px 0;
    }

    .title-wrapper {
      .title {
        @include body-text-1-m;
        color: $ss-gray-100;
      }
    }
  }

  .banner {
    display: flex;
    align-items: center;
    .content-wrapper {
      .content {
        @include h7;
        color: $ss-gray-100;
      }
    }
  }
}

.stack-job-container {
  .position-link {
    text-align: center;
  }
}
